<template>
  <div class="chat-window">
    <template>
      <template v-for="(message, index) in messages">
        <component
          :is="
            message.role === 'assistant'
              ? 'ChatBotMessageAssistant'
              : 'ChatBotMessageUser'
          "
          :key="'message' + index"
          :message="message"
        />
      </template>
      <ChatBotMessage
        v-if="loading"
        color="white"
        :message="{ role: 'assistant', message: 'Thinking...' }"
      >
        <div style="width: 100px">
          <v-progress-linear
            width="200"
            height="5"
            indeterminate
            color="green"
          />
        </div>
      </ChatBotMessage>
      <ChatBotNextQuestions v-if="nextQuestions" />
    </template>
  </div>
</template>
<script lang="ts">
import ChatBotMessage from '@/tt-app-extensions/chat-bot/components/ChatBotMessage.vue'
import ChatBotMessageUser from '@/tt-app-extensions/chat-bot/components/ChatBotMessageUser.vue'
import ChatBotMessageAssistant from '@/tt-app-extensions/chat-bot/components/ChatBotMessageAssistant.vue'
import ChatBotNextQuestions from '@/tt-app-extensions/chat-bot/components/ChatBotNextQuestions.vue'
import ChatVue from '@/tt-app-extensions/chat-bot/components/ChatVue'

export default ChatVue.extend({
  name: 'ChatMessageWindow',
  watch: {
    messages: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          const chatWindow = document.querySelector('.chat-window')
          chatWindow.scrollTop = chatWindow.scrollHeight
        })
      },
    },
  },
  components: {
    ChatBotMessage,
    ChatBotMessageUser,
    ChatBotMessageAssistant,
    ChatBotNextQuestions,
  },
  inheritAttrs: true,
})
</script>
<style scoped>
.chat-window {
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.theme--dark .chat-title {
  background: #333;
  color: #ccc;
  opacity: 0.8;
}
</style>
